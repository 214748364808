<script>
  import SearchAsYouTypeColumn from "./SearchAsYouTypeColumn.svelte";

  export let data;
  export let query;
  export let strings;
</script>

<div class="search-as-you-type__content">
  <div class="search-as-you-type__columns">
    <SearchAsYouTypeColumn
      data={data.hits.Pages}
      category={"pages"}
      categoryName={$strings.categoryHeadingPages}
    />
    <SearchAsYouTypeColumn
      data={data.hits.News}
      category={"news"}
      categoryName={$strings.categoryHeadingNews}
    />
    <SearchAsYouTypeColumn
      data={data.hits.Pressreleases}
      category={"press"}
      categoryName={$strings.categoryHeadingPress}
    />
    <SearchAsYouTypeColumn
      data={data.hits.Jobs}
      category={"jobs"}
      categoryName={$strings.categoryHeadingJobs}
      {strings}
    />
    <SearchAsYouTypeColumn
      data={data.hits.Contacts}
      category={"contacts"}
      categoryName={$strings.categoryHeadingContacts}
      {strings}
    />
    <SearchAsYouTypeColumn
      data={data.hits.Documents}
      category={"documents"}
      categoryName={$strings.categoryHeadingDocuments}
    />
  </div>
  <a
    href="{$strings.searchUrl}{query}"
    class="search-as-you-type__search-button">{$strings.searchBtnText}</a
  >
</div>

<style>
  .search-as-you-type__content {
    position: absolute;
    background-color: #fff;
    border-top: 2px solid #dcdcdc;
    display: flex;
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    flex-direction: column;
    width: clamp(929px, 90vw, 1667px);
    gap: 1.875rem;
    padding: 1.875rem;
    animation: saytAnimation 0.1s ease-in;
    transform-origin: top;
  }

  .search-as-you-type__columns {
    display: flex;
    width: 100%;
    gap: 1.875rem;
    margin: 0;
  }

  .search-as-you-type__search-button {
    height: 44px;
    background-color: #17173c;
    color: #fff;
    cursor: pointer;
    outline: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  @keyframes saytAnimation {
    from {
      transform: scaleY(0);
    }

    to {
      transform: scaleY(1);
    }
  }
</style>
