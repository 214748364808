import App from './App.svelte'
import { strings } from '../store/store'

let target = document.querySelector('#search-as-you-type__header')

strings.set({
  ...target.dataset,
})

const app = new App({
  target,
})

export default app
