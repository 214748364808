<script>
  export let item;
  export let category;
  export let strings = undefined;
</script>

<li>
  <a href={item.url}>
    <p
      class="search-as-you-type__title {category === 'documents'
        ? 'document'
        : ''}"
    >
      {item.name}
    </p>
    {#if item.startPublish}
      <p class="search-as-you-type__date">{item.startPublish}</p>
    {/if}
    {#if item.pubDateTo}
      <p class="search-as-you-type__date">{$strings.applyDateText ? `${$strings.applyDateText}: ` : ''} {item.pubDateTo}</p>
    {/if}
  </a>
</li>

<style>
  li {
    padding: 0;
  }
  li::before {
    display: none;
  }

  .search-as-you-type__date {
    font-size: 0.8rem;
    color: #595959;
  }

  .search-as-you-type__title {
    color: #0063ff;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .search-as-you-type__title.document::after {
    content: "";
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cg data-name='1' fill='%230164ff'%3E%3Cpath d='M377.67 450H148.8a50.17 50.17 0 01-50.11-50.11V98.11A50.17 50.17 0 01148.8 48h150.1a15 15 0 0110.6 4.39l113.88 113.88a15 15 0 014.4 10.61v223A50.17 50.17 0 01377.67 450zM148.8 78a20.13 20.13 0 00-20.11 20.11v301.78A20.13 20.13 0 00148.8 420h228.87a20.13 20.13 0 0020.11-20.11v-216.8L292.68 78z' /%3E%3Cpath d='M412.78 191.88H334a50.17 50.17 0 01-50.11-50.11V63a15 15 0 0130 0v78.77A20.13 20.13 0 00334 161.88h78.77a15 ' /%3E%3C/g%3E%3C/svg%3E");
    position: relative;
    top: 4px;
  }
</style>
