<script>
  export let item;
  export let strings;
</script>

{#if item.imageUrl || item.contactTitle || item.telephone || item.email}
  <li>
    <div class="search-as-you-type__contact-top">
      {#if item.imageUrl}
        <img src={item.imageUrl} alt="bild på {item.name}" />
      {/if}
      <div>
        <p class="search-as-you-type__contact-name">{item.name}</p>
        {#if item.contactTitle}
          <p class="search-as-you-type__contact-job-title">
            {item.contactTitle}
          </p>
        {/if}
      </div>
    </div>
    {#if item.telephone}
      <a
        href={"tel:" + item.telephone}
        class="search-as-you-type__contact-phone"
        title={item.name}
        rel="noopener noreferrer"
      >
        <p>{item.telephone}</p>
      </a>
    {/if}
    {#if item.email}
      <a
        href={"mailto:" + item.email}
        class="search-as-you-type__contact-email"
        title={item.name}
        rel="noopener noreferrer"
      >
        <p>{$strings.contactEmailText}</p>
      </a>
    {/if}
  </li>
{/if}

<style>
  li {
    padding: 0;
  }
  li::before {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .search-as-you-type__contact-email p,
  .search-as-you-type__contact-phone p {
    color: #0063ff;
  }

  .search-as-you-type__contact-top {
    display: flex;
    gap: 5px;
    margin-top: 0;
  }
  .search-as-you-type__contact-top p {
    font-size: 0.8rem;
    color: #595959;
    margin: 0;
  }

  p.search-as-you-type__contact-name {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    margin-bottom: -5px;
  }

  img {
    width: 40px;
    height: 40px;
  }
</style>
