<script>
  import SearchAsYouTypeContactItem from "./SearchAsYouTypeContactItem.svelte";
  import SearchAsYouTypeItem from "./SearchAsYouTypeItem.svelte";

  export let data;
  export let category;
  export let categoryName;
  export let strings = undefined;
</script>

{#if data.length}
<div class="search-as-you-type__column">
  <h4>{categoryName}</h4>
  <ul class="search-as-you-type__items">
    {#each data as item}
      {#if category === "contacts"}
        <SearchAsYouTypeContactItem {item} {strings}/>
      {:else}
        <SearchAsYouTypeItem {item} {category} {strings}/>
      {/if}
    {/each}
  </ul>
</div>
{/if}

<style>
  .search-as-you-type__column {
    flex: 1 1 0px;
  }

  h4 {
    font-size: 1rem;
    font-weight: bold;
    border-width: 2px;
    padding-bottom: 0.5625rem;
    margin-bottom: 1.5rem;
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .search-as-you-type__items {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
</style>
