/** Dispatch event on click outside of node */
export const clickOutside = (node) => {
    const handleClick = (e) => {
        if (node && !node.contains(e.target) && !e.defaultPrevented) {
            node.dispatchEvent(new CustomEvent("clickedOutside", node));
        }
    };

    document.addEventListener("click", handleClick, true);

    return {
        destroy() {
            document.removeEventListener("click", handleClick, true);
        }
    };
};
