<script>
  import { onMount } from "svelte";
  import { strings } from "../store/store";
  import { clickOutside } from "../../svelte-mobile-menu/helpers/clickOutside";
  import SearchAsYouType from "../../svelte-search-as-you-type/components/SearchAsYouType.svelte";

  let showSayt = false;
  let data = [];
  let query = "";

  const getSaytData = async (query) => {
    try {
      const res = await fetch($strings.endpoint + query + "&language=" + $strings.endpointLang);
      const items = await res.json();
      items && (showSayt = true);
      items && (data = items);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickOutside = () => {
    showSayt = false;
  };

  let timeout;
  const debounce = (query) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      getSaytData(query);
    }, 300);
  };

  onMount(() => {
    const queryInputHeader = document.getElementById("queryInput-header");
    queryInputHeader &&
      queryInputHeader.addEventListener("input", function (e) {
        if (window.innerWidth > 991) {
          query = this.value;
          if (this.value.length > 2) {
            debounce(this.value);
          } else {
            showSayt = false;
            data = [];
          }
        }
      });
  });
</script>

{#if showSayt && (data.hits.Pages.length || data.hits.News.length || data.hits.Pressreleases.length || data.hits.Contacts.length || data.hits.Contacts.Documents) && query.length > 2}
  <div
    class="search-as-you-type"
    use:clickOutside
    on:clickedOutside={handleClickOutside}
  >
    <SearchAsYouType {strings} {data} {query} />
  </div>
{/if}

<style>
  .search-as-you-type {
    width: 0;
    height: 0;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    left: 50%;
    top: 0;
  }
  @media (max-width: 991px) {
    .search-as-you-type {
      display: none;
    }
  }
</style>
